/* eslint no-undef: "off" */
/* eslint no-unused-vars: "off" */

const initOutdoorActive = () => {
  const conf = {
    frontendtype: 'tour',
    withUrlHash: false,
    modes: ['list', 'gallery', 'map', 'listMap'],
    zoom: 12,
    center: [9.83092, 46.83454],
    fitDataBounds: true,
    whereType: 'none',
    mapInit: {
      network: 'winter_alpine'
    },
    initMode: 'gallery',
    cfg: {
      initFilterNoGui : {},
      what: {
        selected_init: 'Mountainbikerouten,E-Bike'
      },
      sort: {
        selected_initFromDom : false,
        selected_init_value : 'title',
        key2descendant_init : {
          title : true
        }
      },
      maplayer: {
        mapoverlay: {
          winter_alpine: {}
        }
      }
    }
  }

  oa.api.flexviewpage(conf);
  const elm = document.getElementsByClassName("oax-top-cont");
  let w = window.innerWidth;

  window.onresize = function(event) {
    w = window.innerWidth;
    elm.width = w + 'px';
  };


  elm.width = w + 'px';
}


export default ({ app }, inject) => {
  inject('initOutdoorActive', initOutdoorActive)
}
